import { Injectable } from '@angular/core';
import {
    Client,
    StudentProfileResponse,
    UpdateStudentRequest,
    RegisteredStudent,
    UpdateStudentEmailResponse,
    UpdateStudentEmailRequest
} from '../api/client-student.service';
import { saveAs } from 'file-saver';
import { CacheService } from './cache.service';

export class HttpOptions {
    url: string;
    body?: any;
    cacheMins?: number;
}

@Injectable()
export class StudentService {
    constructor(private client: Client, private _cacheService: CacheService) {}

    async student(options: HttpOptions): Promise<StudentProfileResponse> {
        // Setup default values
        options.body = options.body || null;
        options.cacheMins = options.cacheMins || 0;

        if (options.cacheMins > 0) {
            const data = this._cacheService.load(options.url);
            // Return data from cache
            if (data !== null) {
                return data;
            }
        }

        const response = await this.client.get().toPromise<StudentProfileResponse>();
        if (options.cacheMins > 0) {
            // Data will be cached
            this._cacheService.save({
                key: options.url,
                data: response,
                expirationMins: options.cacheMins
            });
        }
        return response;
    }

    clearCache(key: any) {
      this._cacheService.remove(key);
    }

    async studentUpdate(registerProfileData: any): Promise<RegisteredStudent> {
        const updateStudentRequest = new UpdateStudentRequest();
        updateStudentRequest.init({
            lui: registerProfileData.lui,
            firstName: registerProfileData.firstName,
            lastName: registerProfileData.lastName,
            dateOfBirth: registerProfileData.dob,
            phone: registerProfileData.phone,
            shareDataWithSchool: registerProfileData.optIn,
            receiveResultsByEmail: registerProfileData.sendEmail,
        });

        return await this.client
            .update(updateStudentRequest)
            .toPromise<RegisteredStudent>();
    }

    async studentEmailUpdate(studentEmailEditData: any): Promise<UpdateStudentEmailResponse> {
        const updateStudentEmailRequest = new UpdateStudentEmailRequest();
        updateStudentEmailRequest.init({
            email: studentEmailEditData.currentEmail,
            newEmail: studentEmailEditData.newEmail
        });

        return await this.client
            .updateEmail(updateStudentEmailRequest)
            .toPromise<UpdateStudentEmailResponse>();
    }

    async studentShareDataUpdate(optIn: boolean): Promise<RegisteredStudent> {
        const updateStudentRequest = new UpdateStudentRequest();
        updateStudentRequest.init({
            shareDataWithSchool: optIn
        });

        return await this.client
            .update(updateStudentRequest)
            .toPromise<RegisteredStudent>();
    }

    async studentSendEmailUpdate(optIn: boolean): Promise<RegisteredStudent> {
        const updateStudentRequest = new UpdateStudentRequest();
        updateStudentRequest.init({
            receiveResultsByEmail: optIn
        });

        return await this.client
            .update(updateStudentRequest)
            .toPromise<RegisteredStudent>();
    }

    async printPDF(studentDetails: any, exitYear: any): Promise<any> {
        const filename =
            studentDetails.firstName +
            '_' +
            studentDetails.lastName +
            '_' +
            exitYear +
            '_ATAR.pdf';
        return await this.client.getPdf(exitYear).subscribe((data) => {
            saveAs(
                new Blob([data.data], { type: 'application/pdf' }),
                filename
            );
        });
    }
}
