<div class="app-admin-wrap" [dir]="layoutConf?.dir">
    <!-- Header for top navigation layout -->
    <!-- ONLY REQUIRED FOR **TOP** NAVIGATION LAYOUT -->

    <!-- Main Container -->
    <mat-sidenav-container [dir]="layoutConf.dir">
        <mat-sidenav-content>
            <!-- SIDEBAR -->
            <!-- ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT -->

            <app-sidebar-side
                *ngIf="layoutConf.navigationPos === 'side'"
                (mouseenter)="sidebarMouseenter($event)"
                (mouseleave)="sidebarMouseleave($event)"
            ></app-sidebar-side>
            <!-- Top navigation layout (navigation for mobile screen) -->
            <!-- ONLY REQUIRED FOR **TOP** NAVIGATION MOBILE LAYOUT -->

            <!-- App content -->
            <div
                class="main-content-wrap"
                id="main-content-wrap"
            >
                <!-- Header for side navigation layout -->
                <!-- ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT -->
                <app-header-side
                    *ngIf="layoutConf.navigationPos === 'side'"
                >
                </app-header-side>

                <div
                    class="rightside-content-hold"
                    id="rightside-content-hold"
                >
                    <!-- View Loader -->

                    <!-- View outlet -->
                    <router-outlet></router-outlet>
                </div>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
