import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from '../../../../projects/student-web/src/app/services/student-authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService,
        private _snackBar: MatSnackBar
    ) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const { idToken, accessToken } = this.authenticationService;

        if (idToken && !/user\/([\s\S])*/.test(request.url) && !this.urlRequiresAccessToken(request.url)) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${idToken}`,
                },
            });
        }

        if (accessToken && this.urlRequiresAccessToken(request.url)) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        }

        return next.handle(request).pipe(
            catchError((err) => {
                if (err.status === 401 && !err.url.endsWith('user/login')) {
                    // auto logout if 401 unauthorised response returned from api
                    this.authenticationService.removeTokens();
                    location.reload(true);
                } else if (err.status === 500 || err.status === 503) {
                    this._snackBar.open('Something went wrong', 'Close', {
                        duration: 8000,
                        panelClass: ['fail-snackbar'],
                    });
                } else if (err.status === 429) {
                    this._snackBar.open(
                        'The system is currently experiencing high usage. Please wait a few minutes and try again.',
                        'Close',
                        {
                            duration: 8000,
                            panelClass: ['fail-snackbar'],
                        }
                    );
                }
                return throwError(err);
            })
        );
    }

    urlRequiresAccessToken(url: string): boolean {
        return /user\/logout/.test(url)
            || /user\/verify-email/.test(url)
            || /user\/resend-confirmed-code/.test(url)
            || /student\/update-email/.test(url);
    }
}
