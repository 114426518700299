import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { AuthenticationService } from './student-authentication.service';

@Injectable()
export class UserService {
  private claims: any;
  private reverifyEmail: string;
  user = {
    username: '',
    password: '',
    code: '',
  };

  constructor(private authService: AuthenticationService) {
    this.setClaims();
  }

  public setClaims() {
    if (localStorage.getItem('idToken') != null) {
      this.claims = jwt_decode(localStorage.getItem('idToken'));
      this.reverifyEmail = this.claims['custom:reverifyEmail'];
    }
  }

  public getClaims(): any {
    return this.claims;
  }

  public setUser(email: string) {
    this.user.username = email;
  }

  public getReverifyEmail(): any {
    return this.reverifyEmail;
  }


}
