import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
} from '@angular/router';
import { AuthenticationService } from '../student-authentication.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {}
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const idToken = this.authenticationService.idToken;
        const reverifyEmail = this.authenticationService.reverifyEmail;

        if (idToken && reverifyEmail !== 'true') {
            // logged in and re-verification not required so return true
            return true;
        }
        this.router.navigate(['/sessions/signin']);
        return false;
    }
}
