import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
})
export class NotificationsComponent implements OnInit {
  @Input() notificPanel;

  // Dummy notifications
  notifications = [
    {
      message: 'New contact added',
      icon: 'assignment_ind',
      time: '1 min ago',
      route: '/',
      color: 'primary',
    },
    {
      message: 'Server rebooted',
      icon: 'settings_backup_restore',
      time: '12 min ago',
      route: '/',
      color: 'warn',
    },
  ];

  constructor(private router: Router) {}

  ngOnInit() {
    this.router.events.subscribe((routeChange) => {
      if (routeChange instanceof NavigationEnd) {
        this.notificPanel.close();
      }
    });
  }
  clearAll(e) {
    e.preventDefault();
    this.notifications = [];
  }
}
