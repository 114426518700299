import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {
    BrowserModule,
    HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GestureConfig } from '@angular/material/core';

import { rootRouterConfig } from './app.routing';
import { SharedModule } from '../../../../src/app/shared/shared.module';
import { AppComponent } from './app.component';

import {
    HttpClient,
    HttpClientModule,
    HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ErrorHandlerService } from '../../../../src/app/shared/services/error-handler.service';
import { OAuthModule } from 'angular-oauth2-oidc';
import { ErrorInterceptor } from '../../../../src/app/shared/interceptors/error-interceptor';

import { Client as StudentClient, API_BASE_URL } from './api/client-student.service';
import { environment } from '../environments/environment';
import { UserService } from './services/user.service';
import { StudentService } from './services/student.service';
import { AuthenticationService } from './services/student-authentication.service'
import { AuthGuard } from './services/auth/student-auth.guard'
import { LogoutService } from 'app/shared/services/logout.service';
import {
    BaseConfiguration,
    ClientSettings,
    APP_CONFIG,
  } from './api/base-configuration.service';
import { CorrelationInterceptor } from 'app/shared/interceptors/correlation-interceptor';
import { CacheService } from './services/cache.service';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient);
}

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        SharedModule,
        HttpClientModule,
        OAuthModule.forRoot({
            resourceServer: {
                allowedUrls: ['http://localhost', 'https://qtac'],
                sendAccessToken: true,
            },
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        RouterModule.forRoot(rootRouterConfig, { useHash: false }),
    ],
    declarations: [AppComponent],
    providers: [
        UserService,
        AuthGuard,
        AuthenticationService,
        StudentService,
        StudentClient,
        BaseConfiguration,
        CacheService,
        { provide: APP_CONFIG, useValue: (window as any).c as ClientSettings },
        { provide: API_BASE_URL, useValue: environment.apiURL },
        { provide: LogoutService, useExisting: AuthenticationService },
        { provide: ErrorHandler, useClass: ErrorHandlerService },
        { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: CorrelationInterceptor, multi: true },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
