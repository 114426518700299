import { Injectable } from '@angular/core';
import {
    Client,
    AuthenticationRequest,
    AuthenticationResponse,
    PasswordRequest,
    PasswordResponse,
    SignupRequest,
    ConfirmSignupRequest,
    SignupResponse,
    SignoutReponse,
    ResendCodeRequest,
    VerifyEmailRequest,
    ResendConfirmedCodeRequest
} from '../api/client-student.service';
import { LogoutService } from '../../../../../src/app/shared/services/logout.service';
import { CacheService } from './cache.service';

@Injectable()
export class AuthenticationService implements LogoutService {
    constructor(private client: Client, private _cacheService: CacheService) {}

    public get idToken(): string {
        return localStorage.getItem('idToken');
    }

    public get accessToken(): string {
        return localStorage.getItem('accessToken');
    }

    public get reverifyEmail(): string {
        return (this.accessToken) ? this.accessToken['custom:reverifyEmail'] : '';
    }

    async login(username: string, password: string) {
        const credentials = new AuthenticationRequest();
        credentials.init({
            username: username,
            password: password,
        });

        const authenticationResponse = await this.client
            .login(credentials)
            .toPromise<AuthenticationResponse>();

        if (
            authenticationResponse?.idToken &&
            authenticationResponse?.accessToken
        ) {
            localStorage.setItem('idToken', authenticationResponse.idToken);
            localStorage.setItem(
                'accessToken',
                authenticationResponse.accessToken
            );
        }
    }

    async resendCode(username: string) {
        const resendCodeRequest = new ResendCodeRequest();
        resendCodeRequest.init({
            username: username,
        });

        await this.client
            .resendCode(resendCodeRequest)
            .toPromise<SignupResponse>();
    }

    async resendConfirmedCode(username: string) {
        const resendConfirmedCodeRequest = new ResendConfirmedCodeRequest();
        resendConfirmedCodeRequest.init({
            username: username,
        });

        await this.client
            .resendConfirmedCode(resendConfirmedCodeRequest)
            .toPromise<SignupResponse>();
    }

    async forgotPassword(username: string) {
        const passwordRequest = new PasswordRequest();
        passwordRequest.init({
            username: username,
        });
        await this.client
            .forgotPassword(passwordRequest)
            .toPromise<PasswordResponse>();
    }

    async resetPassword(resetPasswordData: any) {
        const passwordRequest = new PasswordRequest();
        passwordRequest.init({
            username: resetPasswordData.email,
            password: resetPasswordData.password,
            confirmationCode: resetPasswordData.code,
        });
        await this.client
            .resetPassword(passwordRequest)
            .toPromise<PasswordResponse>();
    }

    async signup(signupData: any) {
        const signupRequest = new SignupRequest();
        signupRequest.init({
            username: signupData.email,
            password: signupData.password,
        });
        await this.client.signUp(signupRequest).toPromise<SignupResponse>();
    }

    async confirmSignup(user: any) {
        const confirmSignupRequest = new ConfirmSignupRequest();
        confirmSignupRequest.init({
            email: user.username,
            confirmationCode: user.code,
        });
        await this.client
            .confirmSignUp(confirmSignupRequest)
            .toPromise<SignupResponse>();
    }

    async verifyEmail(user: any) {
        const verifyEmailRequest = new VerifyEmailRequest();
        verifyEmailRequest.init({
            email: user.username,
            confirmationCode: user.code,
        });
        await this.client
            .verifyEmail(verifyEmailRequest)
            .toPromise<SignupResponse>();
    }

    async logout() {
        this._cacheService.remove('student');
        await this.client.logout().toPromise<SignoutReponse>();
        this.removeTokens();
    }

    removeTokens() {
        localStorage.removeItem('idToken');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
    }
}
