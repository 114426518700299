const currentOrigin = window.location.origin;
let apiURL;

if (currentOrigin.includes('dev')) {
  apiURL = 'https://atar-student-service.dev.qtac.io';
} else if (currentOrigin.includes('int')) {
  apiURL = 'https://atar-student-service.int.qtac.io';
} else if (currentOrigin.includes('qa')) {
  apiURL = 'https://atar-student-service.qa.qtac.io';
} else if (currentOrigin.includes('staging')) {
  apiURL = 'https://atar-student-service.staging.qtac.io';
} else if (currentOrigin.includes('o2beta')) {
  apiURL = 'https://atar-student-service.o2beta.qtac.edu.au';
} else if (currentOrigin.includes('prod')) {
  apiURL = 'https://atar-student-service.prod.qtac.edu.au';
} else {
  apiURL = 'https://atar-student-service.prod.qtac.edu.au';
}

export const environment = {
  production: true,
  apiURL: apiURL
};
