<div class="sidebar-panel">
    <div
      id="scroll-area"
      class="navigation-hold"
      fxLayout="column"
    >
      <div class="sidebar-hold">
  
        <app-sidenav
          [items]="menuItems"
          [hasIconMenu]="hasIconTypeMenuItem"
          [iconMenuTitle]="iconTypeMenuTitle"
        ></app-sidenav>
      </div>
    </div>
  </div>
  