import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StudentLayoutComponent } from 'app/shared/components/layouts/student-layout/student-layout.component';
import { AuthLayoutComponent } from 'app/shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './services/auth/student-auth.guard';

export const rootRouterConfig: Routes = [
    {
        path: '',
        redirectTo: 'student',
        pathMatch: 'full',
    },
    {
        path: '',
        component: AuthLayoutComponent,
        children: [
            {
                path: 'sessions',
                loadChildren: () =>
                    import('./views/sessions/sessions.module').then(
                        (m) => m.SessionsModule
                    ),
                data: { title: 'Session' },
            },
        ],
    },
    {
        path: '',
        component: StudentLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'student',
                loadChildren: () =>
                    import('./views/student/student.module').then(
                        (m) => m.StudentModule
                    ),
                data: { title: 'Student', breadcrumb: 'STUDENT' },
            },
        ],
    },
    {
        path: '**',
        redirectTo: 'student',
    },
];
