import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LogoutService } from 'app/shared/services/logout.service';

@Component({
  selector: 'app-header-side',
  templateUrl: './header-side.template.html',
})
export class HeaderSideComponent implements OnInit {
  constructor(
    private authService: LogoutService,
    private router: Router,
  ) {}
  ngOnInit() {}

  async logout() {
    try {
      await this.authService.logout();
      this.router.navigate(['/sessions/signin']);
    } catch (error) {
      this.authService.removeTokens();
      location.reload(true);
    }
  }
}
