<mat-toolbar class="topbar">
  <div>
    <img
      src="../../../../assets/images/QTAC-white-logo.png"
      alt=""
      class="qtac-logo"
    />
  </div>
  <div>
    <button mat-raised-button data-test="logout" (click)="logout()">
      <mat-icon>exit_to_app</mat-icon>
      LOG OUT
    </button>
  </div>
</mat-toolbar>
