import { Injectable, InjectionToken } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';


export const APP_CONFIG = new InjectionToken<ClientSettings>('APP_CONFIG');

@Injectable()
export class BaseConfiguration {

    constructor(
        private _snackBar: MatSnackBar, private _router : Router) { }

    public getSnackBar(): MatSnackBar {
        return this._snackBar;
    }

    public getRouter(): Router {
        return this._router;
    }
}

export class ClientSettings {
    instrumentationKey?: string | undefined;
    apiBase?: string | undefined;

    init(data?: any) {
        if (data) {
            this.instrumentationKey = data["instrumentationKey"];
            this.apiBase = data["apiBase"];
        }
    }

    static fromJS(data: any): ClientSettings {
        data = typeof data === 'object' ? data : {};
        let result = new ClientSettings();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["instrumentationKey"] = this.instrumentationKey;
        data["apiBase"] = this.apiBase;
        return data; 
    }
}
