import { Injectable } from '@angular/core';

@Injectable()
export class ErrorService {

  constructor() {
  }

  public mapError(error) {
    if (error.includes('email already exists')) {
      return 'An account with the given email already exists';
    } else if (error.includes('Invalid code provided')) {
      return 'Confirmation code is not valid, please request a code again.';
    } else if (error.includes('Invalid verification code')) {
      return 'Invalid confirmation code provided, please try again.';
    } else if (error.includes('LUI is already used')) {
      return 'This LUI is already registered to an existing account.';
    } else if (error.includes('Unable to update student to a record that is already matching QCAA data')) {
      return 'This LUI is already registered to an existing account.';
    } else if (error.includes('Too many requests')) {
      return 'The system is currently experiencing high usage. Please wait a few minutes and try again.';
    }
    return error;
  }
}
